<template>
  <v-app>
    <AppHeaderUser v-if="user" />
    <AppHeader v-else />
    <div
      id="terms_container"
      :style="{ paddingTop: blank ? '60px' : '' }"
      style="height: 100%; width: 100%; background-color: #f7f7f7"
    >
      <v-row v-if="!blank" justify="start" align="center">
        <v-col
          cols="auto"
          class="pointer"
          style="
            margin-top: 40px;
            padding-top: 0;
            padding-left: 25px;
            padding-right: 0;
          "
        >
          <div
            @click="$router.back()"
            id="terms-back-icon-e6b29300"
            :class="{ point: hover }"
            style="height: 35px"
            @mouseover="hover = true"
            @mouseleave="hover = false"
          >
            <Images name="arrow.svg" />
          </div>
        </v-col>
        <v-col cols="auto" style="padding-top: 41px">
          <Texting
            id="terms-backtext-f66642a6"
            @click.native="$router.back()"
            class="pointer"
            type="p1bold grey2"
            >Back</Texting
          >
        </v-col>
      </v-row>
      <v-row justify="center" style="padding-bottom: 140px">
        <div
          class="d-none d-md-flex"
          style="
            padding-top: 28px;
            padding-bottom: 50px;
            width: 100%;
            margin-left: 34%;
          "
        >
          <Heading type="h1 dark-asphalt">Terms and Conditions</Heading>
        </div>
        <div
          class="d-flex d-md-none col-auto"
          style="padding-top: 28px; padding-bottom: 50px; text-align: center"
        >
          <Heading type="h1 dark-asphalt">Terms and Conditions</Heading>
        </div>
        <v-row
          style="width: 100%"
          class="justify-content-center"
          justify="center"
          v-for="item in items"
          :key="item.text"
        >
          <v-col cols="10" md="4">
            <Texting type="p2 asphalt" v-html="item.text" />
          </v-col>
        </v-row>
      </v-row>
      <v-row
        justify="center"
        justify-md="start"
        style="padding-bottom: 140px"
        v-for="item in terms"
        :key="item.header"
      >
        <v-col
          cols="10"
          md="4"
          class="text-center text-md-left"
          style="padding-top: 5px"
        >
          <Heading type="h3 dark-asphalt" class="ml-md-10 ml-0 pl-md-10 pl-0">{{
            item.header
          }}</Heading>
        </v-col>
        <v-col cols="10" md="4">
          <Texting type="p2 asphalt" v-html="item.content" />
        </v-col>
      </v-row>
    </div>
    <AppFooter />
  </v-app>
</template>

<script>
import Texting from "../components/Texting";
import Heading from "../components/Heading";
import AppHeaderUser from "../components/AppHeaderUser";
import AppHeader from "../components/AppHeader";
import AppFooter from "../components/AppFooter";
import Images from "../components/MyImages";
import { mapState } from "vuex";

export default {
  components: {
    Heading,
    Texting,
    AppHeaderUser,
    Images,
    AppHeader,
    AppFooter,
  },
  computed: {
    ...mapState({
      user: "user",
    }),
  },
  mounted() {
    this.$nextTick(() => {
      let element = document.getElementById("terms_container");
      this.$scrollTo(element, 100, { offset: -100, easing: "linear" });
    });
    if (this.$route.query.blank === "true") {
      this.blank = true;
    }
  },
  data: () => ({
    hover: false,
    loading: false,
    blank: false,
    terms: [
      {
        header: "Cookies",
        content:
          "We employ the use of cookies. By accessing Perkfection, you\n" +
          "                    agree to use cookies in agreement with Perkfection's Privacy\n" +
          "                    Policy.<br><br>" +
          "Most interactive websites use cookies to help retrieve the\n" +
          "                        user’s details for each visit and create a more personalized\n" +
          "                        and advanced user experience. Cookies are used by our\n" +
          "                        Website to enable the functionality of certain areas, to make\n" +
          "                        navigating easier for people visiting our Website. Some of\n" +
          "                        our affiliate/advertising partners may also use cookies.",
      },
      {
        header: "Content Liability",
        content:
          "We shall not be held responsible for any content that\n" +
          "                    appears on your Website. You agree to protect and defend us\n" +
          "                    against all claims that rise from your Website. No link(s)\n" +
          "                    should appear on any Website that may be interpreted as\n" +
          "                    libelous, obscene or criminal, or which infringes, otherwise\n" +
          "                    violates, or advocates the infringement or other violation of,\n" +
          "                    any third party rights.<br><br>" +
          "We reserve the right to request removal of certain links and\n" +
          "                    content from your website.",
      },
      {
        header: "Disclaimer",
        content:
          "The materials on Perkfections’ Website are provided on an\n" +
          "                    ‘as is’ basis. Perkfection makes no warranties, expressed or\n" +
          "                    implied, and hereby disclaims and negates all other\n" +
          "                    warranties including, without limitation, implied warranties or\n" +
          "                    conditions of merchantability, fitness for a particular purpose,\n" +
          "                    or non-infringement of intellectual property or other violation\n" +
          "                    of rights.<br><br>" +
          "Further, Perkfection does not warrant or make any\n" +
          "                    representations concerning the accuracy, likely results, or\n" +
          "                    reliability of the use of the materials on our Website or\n" +
          "                    otherwise relating to such materials or on any sites linked to\n" +
          "                    this site.",
      },
      {
        header: "Limitations",
        content:
          "In no event shall Perkfection or its suppliers be liable for any\n" +
          "                    damages (including, without limitation, damages for loss of\n" +
          "                    data or profit, or due to business interruption) arising out of\n" +
          "                    the use or inability to use the materials on Perkfections\n" +
          "                    Website, even if Perkfection or a Perkfection-authorized\n" +
          "                    representative has been notified orally or in writing of the\n" +
          "                    possibility of such damage. Because some jurisdictions do\n" +
          "                    not allow limitations on implied warranties, or limitations of\n" +
          "                    liability for consequential or incidental damages, these\n" +
          "                    limitations may not apply to you.",
      },
      {
        header: "Accuracy of materials",
        content:
          "The materials appearing on Perkfections’ Website could\n" +
          "                    include technical, typographical, or photographic errors.\n" +
          "                    Perkfection does not warrant that any of the materials on its\n" +
          "                    Website are accurate, complete or current. Perkfection may\n" +
          "                    make changes to the materials contained on our Website at\n" +
          "                    any time without notice. However Perkfection does not make\n" +
          "                    any commitment to update the materials.",
      },
      {
        header: "Modifications",
        content:
          "Perkfection may revise these terms of service for our Website\n" +
          "                    at any time without notice. By using this Website you are\n" +
          "                    agreeing to be bound by the then-current version of these\n" +
          "                    terms of service.",
      },
      {
        header: "Payments & Refunds",
        content:
          "Our order process is conducted by our online reseller\n" +
          "                    Stripe.com. Stripe.com is the Merchant of Record for all our\n" +
          "                    orders. Stripe provides all customer service inquiries and\n" +
          "                    handles returns. Refunds/credits are available on a prorated\n" +
          "                    basis if a customer cancels their subscription early by\n" +
          "                    contacting us.",
      },
      {
        header: "Subscription Terms",
        content:
          'PLEASE READ THESE SUBSCRIPTION TERMS (<b>"TERMS"</b>) CAREFULLY AND BE SURE THAT YOU UNDERSTAND THEM BEFORE YOU PURCHASE A SUBSCRIPTION WITH PERKFECTION.<br><br>\n' +
          'Perkfection offers four different recurring payment options “Subscription Plans” to subscribe to the Weekly, Monthly, 3-Month and 6-Month: (1) an automatically billed weekly ("Weekly Subscription"); (2) an automatically billed monthly ("Monthly Subscription"); (3) an automatically billed 3-Month (" 3-Month Subscription"  and (4) an automatically billed 6-Month ("6-Month Subscription").<br><br>' +
          "\n" +
          "By purchasing one of the Subscription Plans (“Weekly Subscription”, “Monthly Subscription”, “3-Month Subscription”, “6-Month Subscription”), you agree to an initial <b>and recurring Subscription Plan fee</b> at the then-current  Subscription Plan rate, and you accept responsibility for all recurring charges until you cancel your subscription plan. You may cancel your Subscription Plan at any time, subject to the terms of our cancellation policy.<br><br>" +
          "<ul>\n" +
          "  <li><b>AUTOMATIC RENEWAL TERMS: Once you subscribe Perkfection will automatically process your Subscription Plan fee in the next billing cycle.  <br>\n" +
          "Billing cycle for “Weekly Subscription” is 7 days, for “Monthly Subscription” is 30 days, for “3-Month Subscription” is 90 days, and for “6-Month Subscription” is 180 days. Perkfection will continue to automatically process your Subscription Plan fee each time at the then-current  Subscription Plan rate until you cancel your subscription. You may cancel your Subscription Plan anytime before the next billing cycle, subject to the terms of our cancellation policy. </b></li>\n" +
          "</ul><br>" +
          "<b>Cancellation Policy for Subscription Renewals:</b><br><br>\n" +
          "You may cancel your Subscription Plan at any time by logging into your account, clicking on the Campaign Configuration icon, from where u can cancel Subscription Plan by clicking “Cancel Subscription”.  If you need help, feel free to contact us via email: info@perkfection.com.  If you cancel your Subscription Plan, the cancellation will take effect for your next billing cycle. You will not be eligible for a refund for subscription fees paid prior to the billing cycle the cancellation takes effect. <br>\n" +
          "PLEASE NOTE: You must cancel your subscription at least on the last day of the subscription plan if you don’t want to have Perkfection’s service for the next billing cycle.<br>\n" +
          "If you don't want to continue having service and unfortunately you forgot to cancel the service and have been charged according to your Subscription Plan you have only <b>24 hours</b> to cancel your subscription then get our support team to get a refund.<br><br>" +
          "If you cancel your Perkfection Subscription Plan you can re-subscribe to one of them any time.",
      },
      {
        header: "Contact Information",
        content:
          "You can contact Perkfection at any time via email &nbsp &nbsp &nbsp &nbsp &nbsp &nbsp &nbsp &nbsp  \n" +
          "                    info@perkfection.com",
      },
    ],
    items: [
      {
        text:
          "These terms and conditions outline the rules and regulations\n" +
          "                    for the use of Perkfection's Website, found at\n" +
          "                    https://www.perkfection.com.",
      },
      {
        text:
          "By accessing this Website, you accept the Terms and\n" +
          "                    Conditions lain out on this page. We may change these\n" +
          "                    Terms and Conditions at any time and without warning, and\n" +
          "                    we encourage you to refer back to this document often. You\n" +
          "                    may stop using Perkfection if you do not agree to any of the\n" +
          "                    Terms and Conditions stated on this page.",
      },
      {
        text:
          "The following terminology applies to these Terms and\n" +
          "                    Conditions, Privacy Statement and Disclaimer Notice and all\n" +
          '                    Agreements: "Client", "You" and "Your" refers to you, the\n' +
          "                    person logged into this Website and compliant to the\n" +
          '                    Company’s Terms and Conditions. “Perkfection”, "The\n' +
          '                    Company", "Ourselves", "We", "Our" and "Us", refers to our\n' +
          '                    Company. "Party", "Parties", or "Us", refers to both the You\n' +
          "                    and Us.",
      },
      {
        text:
          "Any use of the above terminology or other words in the\n" +
          "                    singular, plural, capitalization and/or he/she or they, are taken\n" +
          "                    as interchangeable and therefore as referring to same.",
      },
    ],
  }),
  metaInfo: {
    meta: [
      {
        name: "description",
        content:
          "These rules and regulations are entered into by and between you and Perkfection. Start your free trial today!",
      },
      {
        property: "og:description",
        content:
          "These rules and regulations are entered into by and between you and Perkfection. Start your free trial today!",
      },
      {
        name: "keywords",
        content: "kickstarter rewards rules",
      },
    ],
  },
};
</script>
